document.addEventListener('DOMContentLoaded', () => {

  /* PHONE VALIDATION */
  function phoneValidation (event) {
    let string = event.target.value;
    let value = string.slice(2).replace(/\D/g, '');
    event.target.value = '';
    let arr = value.split('');
    let rdyArr = ['+7'];
    let result = '';
    arr.forEach(function(digit, index) {
        if (index == 0) {
            rdyArr.push(' (');
        }
        if (index == 3) {
            rdyArr.push(') ');
        }
        if (index == 6 || index == 8) {
            rdyArr.push(' ');
        }
        if (index > 9) {
            return;
        }
        rdyArr.push(digit);
    })
    rdyArr.forEach(function (str) {
        result += str;
    })
    event.target.value = result;
  }

  const phoneInputs = document.querySelectorAll('input[type="tel"]');
  if(phoneInputs) {
    phoneInputs.forEach(phone => {
      phone.addEventListener('input', phoneValidation);
      phone.addEventListener('focus', phoneValidation);
      phone.addEventListener('change', phoneValidation);
    })
  }

  // PLUS MINUS BUTTONS

  const inputQuantity = document.querySelectorAll('input[name="quantity"]');
  inputQuantity.forEach(input => {
    input.addEventListener('input', event => {
      let string = event.target.value;
      let value = string.replace(/\D/g, '');
      let arr = value.split('');
      let tempArr = [];
      let result = '';
      event.target.value = '';
      arr.forEach(function(digit, index) {
        if (index > 11) {
          return;
        }
        tempArr.push(digit);
      })
      tempArr.forEach(function (str) {
        result += str;
      })
      event.target.value = result;
    })
  })

const quantityKit = document.querySelectorAll('.basket-quantity');
if(quantityKit) {
  quantityKit.forEach(el => {
    el.addEventListener('click', e => {
      let input = el.querySelector('input');

      let evt = document.createEvent('HTMLEvents');
      evt.initEvent('change', true, true);
      input.dispatchEvent(evt);

      let value = parseInt(input.value);
      if(e.target.closest('.btn-minus')) {
        input.value = input.value <= 1 ? input.value = 1 : value - 1;
      }
      if(e.target.closest('.btn-plus')) {
        input.value = input.value >= 100 ? input.value = 100 : value + 1;
      }
      input.addEventListener('change', e => {
        input.value = input.value >= 100 ? input.value = 100 : input.value;
      })
    })
  })
}

})

$(function() {

  $('.projects-slider').slick({
    prevArrow: $('.projects .slick-prev'),
    nextArrow: $('.projects .slick-next'),
    fade: true,
  });

  $('.modal-overlay').on('click', function(e) {
    $('.modal').fadeOut(300);
    $('.modal-overlay').fadeOut(300);
  })

  $('body').on('keydown', function(e) {
    if(e.which == 27) {
      $('.modal').fadeOut(300);
      $('.modal-overlay').fadeOut(300);
    }
  })

  $('.menu-link').on('click', function(e) {
    e.preventDefault();
    $('.header').toggleClass('active');
  })

  $('.callback-link').on('click', function(e) {
    e.preventDefault();
    $('.modal-callback').fadeIn(300);
    $('.modal-overlay').fadeIn(300);
  });

  $('.solution-callback').on('click', function(e) {
    e.preventDefault();
    $('.modal-callback').fadeIn(300);
    $('.modal-overlay').fadeIn(300);
  });

  $('.open-code').on('click', function(e) {
    e.preventDefault();
    $('.modal-code').fadeIn(300);
    $('.modal-overlay').fadeIn(300);
  });

  $('.modal-close').on('click', function(e) {
    $(this).parents('.modal').fadeOut(300);
    $('.modal-overlay').fadeOut(300);
  })

  $(document).on('wheel', function(e) {
    $('.modal').fadeOut(300);
    $('.modal-overlay').fadeOut(300);
  })

  $('input[type="tel"]').on('input focus change', function(e) {
    $(e.target).parent().removeClass('is-invalid');
  })

  $('.header-data .header-link').on('click', function(e) {
    e.preventDefault();
    $('.header-data').toggleClass('active');
  })

  $("#callback").submit(function (e) {
    const input = $(this).find('input[type="tel"]');
    const th = $(this);

    if(input.val().length < 18) {
      e.preventDefault();
      input.focus();
      input.parent().addClass('is-invalid');
    } else {
      console.log('Trying to send the form');
      return $.ajax({
        type: "POST",
        url: "send.php",
        data: th.serialize()
      }).done(function () {
        ym(61468528,'reachGoal','callback');
        console.log('Ok!');
        $('.modal').fadeOut(300);
        $('.modal-overlay').fadeOut(300);
        $(".modal-thanks").fadeIn(300);
        setTimeout(function () {
          th.trigger("reset");
          $(".modal-thanks").fadeOut(300);
        }, 2e3);
      }), !1;
    }
  });
  $("#code").submit(function (e) {
    const input = $(this).find('input[type="tel"]');
    const th = $(this);

    if(input.val().length < 18) {
      e.preventDefault();
      input.focus();
      input.parent().addClass('is-invalid');
    } else {
      console.log('Trying to send the form');
      return $.ajax({
        type: "POST",
        url: "send.php",
        data: th.serialize()
      }).done(function () {
        ym(61468528,'reachGoal','code')
        console.log('Ok!');
        $('.modal').fadeOut(300);
        $('.modal-overlay').fadeOut(300);
        $(".modal-thanks").fadeIn(300);
        setTimeout(function () {
          th.trigger("reset");
          $(".modal-thanks").fadeOut(300);
        }, 2e3);
      }), !1;
    }
  });
  $("#calc_form").submit(function (e) {
    let input = $(this).find('input[type="tel"]');
    let th = $(this);

    if(input.val().length < 18) {
      e.preventDefault();
      input.focus();
      input.parent().addClass('is-invalid');
    } else {
      console.log('Trying to send the form');
      return $.ajax({
        type: "POST",
        url: "send.php",
        data: th.serialize()
      }).done(function () {
        // ym(61468528,'reachGoal','smeta')
        console.log('Ok!');
        $('.modal').fadeOut(300);
        $('.modal-overlay').fadeOut(300);
        $(".modal-thanks").fadeIn(300);
        setTimeout(function () {
          th.trigger("reset");
          $(".modal-thanks").fadeOut(300);
        }, 2e3);
      }), !1;
    }
  });
// ym(61468528,'reachGoal','smeta')
// ym(61468528,'reachGoal','code')
})
